import React from "react";

import Layout from "../components/organisms/Layout/Layout";
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import Section from "../components/organisms/Section/Section";
import CartPage from "../components/organisms/Shop/CartPage/CartPage";

const NotFoundPage = () => (
  <>
    <CartPage />
    <Layout>
      <Section>
        <PageWidth>
          <h1>Hoppla!</h1>
          <p>
            Diese Seite ist wohl schon von fleißigen Arbeiterinnen (
            <span role="img" aria-label="Bienen">
              🐝🐝🐝
            </span>
            ) weggetragen worden. Sie existiert leider nicht (mehr).
          </p>
        </PageWidth>
      </Section>
    </Layout>
  </>
);

export default NotFoundPage;
